import {
  Banner,
  Box,
  ContactUsEmailLink,
  ContactUsPhoneLink,
  Container,
  Heading,
  Helmet,
  ReadMoreLink,
  Row,
  Section,
  Theme,
  routesObject
} from "@life-without-barriers/react-components"

import { IGatsbyImageData } from "gatsby-plugin-image"
import Layout from "../../../components/disability/Layout"
import { QcssContactForm } from "@life-without-barriers/shared-contact-form"
import React from "react"
import { Site } from "@life-without-barriers/gatsby-common"
import { graphql } from "gatsby"

const { disabilityTheme } = Theme

interface Props {
  location: { pathname: string }
  data: {
    site: Site
    bannerImage: IGatsbyImageData
  }
}

const bannerBreadcrumbs = [
  routesObject.disabilityHome,
  {
    to: "/disability/services",
    text: "Disability services",
    title: "Return navigation"
  }
]

const ExternalRegisterSection = () => (
  <>
    <Heading size={3}>Ready to register now?</Heading>
    <p>
      Alternatively, you can register for an assessment yourself via the QCSS
      Access Point online registration form.
    </p>
    <ReadMoreLink
      href="https://www.serviceavailabilityregister.com.au/"
      text="Register at QCSS Access Point"
      className="mt3"
      rooted
      ga={{
        eventCategory: "QCSS Page",
        eventLabel: "Register at QCSS Access Point"
      }}
      styles={{
        color: disabilityTheme.dark,
        hoverColor: disabilityTheme.xdark,
        iconColor: disabilityTheme.dark
      }}
    />
  </>
)

const QueenslandCommunitySupportSchemeQcss = ({
  location,
  data: {
    site: { siteMetadata },
    bannerImage
  }
}: Props) => {
  return (
    <div>
      <Helmet
        title={`Queensland Community Support Scheme (QCSS) | ${siteMetadata.title}`}
        description="Eligible Queenslanders can access a range of our daily living and lifestyle services that support you at home and in your local community."
        siteUrl={siteMetadata.siteUrl}
        path={location.pathname}
        image={bannerImage}
      />
      <Layout
        fixedCTAPhoneNumber="1800 573 128"
        fixedCTAContact={<QcssContactForm formId="qcss-fixed-cta" />}
      >
        <Banner
          title="Queensland Community Support Scheme (QCSS)"
          breadcrumbs={bannerBreadcrumbs}
          image={bannerImage}
        />
        <Container>
          <Row>
            <Box className="layout-readable">
              <Heading size={2}>
                What is the Queensland Community Support Scheme?
              </Heading>
              <p>
                The Queensland Community Support Scheme (QCSS) is a program that
                provides support, usually between 1-5 hours per week, for people
                living with a disability or condition that impacts their ability
                to live independently and access the community.
              </p>
              <Heading size={2} topMargin="standard">
                Who’s eligible?
              </Heading>
              <p>
                To be eligible for QCSS support you need to be all of the
                following:
              </p>
              <ul>
                <li>living in Queensland,</li>
                <li>
                  under the age of 65 or under 50 years old for Aboriginal and
                  Torres Strait Islanders,
                </li>
                <li>
                  living with disability, chronic medical illness, mental health
                  or other condition which impacts your daily living and your
                  ability to participate in the community,
                </li>
                <li>
                  require support to remain living independently at home but are
                  not eligible for the National Disability Insurance Scheme
                  (NDIS) or My Aged Care services.
                </li>
              </ul>
              <Heading size={2} topMargin="standard">
                What if I’m not eligible?
              </Heading>
              <p>
                If you require support but aren’t eligible for QCSS, our team
                can connect you with other programs or services that may be able
                to help.
              </p>
              <Heading size={2} topMargin="standard">
                What services are available?
              </Heading>
              <p>
                Through QCSS, you can access a range of our daily living and
                lifestyle services that support you at home and in your local
                community. For example, help with grocery shopping and getting
                to medical appointments, support with showering and medication,
                cleaning and household chores, meal preparation, social
                activities and community connection.
              </p>
              <Heading size={2} topMargin="standard">
                How much does it cost?
              </Heading>
              <p>
                QCSS supports are funded by the Queensland Government, however a
                small contribution is required to access the supports. If you
                are unable to pay this contribution, financial hardship options
                are available that we can discuss with you.
              </p>
            </Box>
          </Row>
        </Container>
        <Section background="bg-lwb-theme-xxx-light">
          <Container>
            <Row className="flex-wrap">
              <Box className="w-100 w-seven-twelfths-l layout-gap">
                <Heading size={2}>How do I apply?</Heading>
                <Heading size={3}>Learn more about QCSS supports</Heading>
                <p>
                  Get in touch with us to discuss how we can help you access
                  QCSS and tailor our services to meet your needs. You can call,
                  email or fill out our online enquiry form.
                </p>
                <div className="flex flex-column flex-row-l pv3">
                  <div className="w-100 w-40-l">
                    <ContactUsPhoneLink
                      styles={{ iconBackgroundColor: "#FFF" }}
                      phoneNumber="1800573128"
                    />
                  </div>
                  <div className="w-100 w-60-l">
                    <ContactUsEmailLink
                      text="qcss@lwb.org.au"
                      link="mailto:qcss@lwb.org.au"
                      styles={{ iconBackgroundColor: "#FFF" }}
                    />
                  </div>
                </div>
                <div className="dn db-l">
                  <ExternalRegisterSection />
                </div>
              </Box>
              <Box className="w-100 w-third-l">
                <div className="mt4">
                  <QcssContactForm formId="qcss-contact-form" />
                  <div className="db dn-l">
                    <ExternalRegisterSection />
                  </div>
                </div>
              </Box>
            </Row>
          </Container>
        </Section>
      </Layout>
    </div>
  )
}

export const query = graphql`
  {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    bannerImage: file(
      relativePath: {
        regex: "/queensland-community-support-scheme-qcss-banner2.jpg/"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1200, quality: 90)
      }
    }
  }
`

export default QueenslandCommunitySupportSchemeQcss
